<template>
  <StatsCard
    :class="[
      { '--loading-data': loadingUserStats },
      { '--loading-data --loading-data--error': errorUserStats },
    ]"
    :loading-msg="errorUserStats ? errorUserStats : 'Fetching Stats...'"
    style="min-height: 180px;"
  >

    <template v-if="!loadingUserStats && !!userStats && !errorUserStats" v-slot:col-data-1>

      <p class="tw-text-base tw-font-bold leading-19 tw-mt-4 tw-mx-4 tw-capitalize">{{ userTypeName }}s</p>
      <NumberGraph
        class="tw-text-app-dark-yellow tw-mt-3 tw-mx-4"
        :data="userStats.total"
      />

    </template>

    <template v-if="!loadingUserStats && !!userStats && !errorUserStats" v-slot:col-data-2>

      <div class="tw-text-center">
        <p class="tw-text-base tw-font-bold leading-19 tw-mt-4 tw-mx-4 tw-capitalize">New {{ userTypeName }}s</p>
        <StatsByRange
          class="tw-mt-3"
          range-container-class="tw-px-4"
          :ranges="['this week', 'this month']"
          :stats="[
            userStats.new.thisWeek,
            userStats.new.thisMonth,
          ]"
          :stats-types="[
            'line-calendar-weekly',
            'bar-calendar-monthly'
          ]"
        />
      </div>

    </template>
  </StatsCard>
</template>

<script>
import { mapGetters } from 'vuex';

import StatsCard from '@/components/StatsCard.vue';
import StatsByRange from '@/components/StatsByRange.vue';
import NumberGraph from '@/components/NumberGraph.vue';

export default {
  name: 'UserStatsCard',
  components: {
    StatsCard,
    StatsByRange,
    NumberGraph,
  },
  props: {
    userTypeId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      loadingUserStats: true,
      errorUserStats: undefined,
    };
  },
  computed: {
    ...mapGetters('AccountsStats', [
      'mentorsStats',
      'menteesStats',
    ]),
    userTypeName() {
      return this.$store.getters.userTypeName(this.userTypeId);
    },
    accountTypes() {
      return this.$store.state.User.accountTypes;
    },
    userStats() {
      if (this.userTypeId === this.accountTypes.mentor) return this.mentorsStats;
      if (this.userTypeId === this.accountTypes.mentee) return this.menteesStats;
      return {};
    },
  },
  methods: {
    async getUserTypeStats() {
      if (!this.userTypeName) return;

      this.loadingUserStats = true;
      this.errorUserStats = undefined;

      await this.$nextTick();
      // ! somehow the store needs it to be in plural
      const result = await this.$store.dispatch('AccountsStats/userTypeAccStats', `${this.userTypeName}s`)
        .catch((err) => {
          console.warn('getUserTypeStats', err);

          this.$toasted.global.appError();
          this.errorUserStats = 'ERROR MESSAGE';
          return false;
        });

      await this.$nextTick();

      if (result) {
        this.errorUserStats = undefined;
      }
      this.loadingUserStats = false;
    },
  },
  async created() {
    await this.getUserTypeStats();
    // recalculate AppView
    this.$emit('mounted');
  },
};
</script>

<style>

</style>
