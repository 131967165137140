<template>
  <div>
    <ContentLoader
      :width="400"
      :height="96"
      :speed="0"
      class="transform-scale-0"
      primaryColor="#f3f3f3"
      secondaryColor="#ecebeb"
    >
      <rect x="0" y="0" rx="10" ry="10" width="56" height="56" />
      <rect x="0" y="64" rx="3" ry="3" width="56" height="10" />
      <rect x="0" y="78" rx="3" ry="3" width="56" height="10" />
      <rect x="110" y="0" rx="10" ry="10" width="56" height="56" />
      <rect x="110" y="64" rx="3" ry="3" width="56" height="10" />
      <rect x="110" y="78" rx="3" ry="3" width="56" height="10" />
      <rect x="220" y="0" rx="10" ry="10" width="56" height="56" />
      <rect x="220" y="64" rx="3" ry="3" width="56" height="10" />
      <rect x="220" y="78" rx="3" ry="3" width="56" height="10" />
      <rect x="330" y="0" rx="10" ry="10" width="56" height="56" />
      <rect x="330" y="64" rx="3" ry="3" width="56" height="10" />
      <rect x="330" y="78" rx="3" ry="3" width="56" height="10" />
    </ContentLoader>
  </div>
</template>

<script>
import { ContentLoader } from 'vue-content-loader';

export default {
  name: 'ContentLoadingTopUsers',
  components: {
    ContentLoader,
  },
};
</script>

<style>

</style>
