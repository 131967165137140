var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('StatsCard',{class:[
    { '--loading-data': _vm.loadingUserStats },
    { '--loading-data --loading-data--error': _vm.errorUserStats } ],staticStyle:{"min-height":"180px"},attrs:{"loading-msg":_vm.errorUserStats ? _vm.errorUserStats : 'Fetching Stats...'},scopedSlots:_vm._u([(!_vm.loadingUserStats && !!_vm.userStats && !_vm.errorUserStats)?{key:"col-data-1",fn:function(){return [_c('p',{staticClass:"tw-text-base tw-font-bold leading-19 tw-mt-4 tw-mx-4 tw-capitalize"},[_vm._v(_vm._s(_vm.userTypeName)+"s")]),_c('NumberGraph',{staticClass:"tw-text-app-dark-yellow tw-mt-3 tw-mx-4",attrs:{"data":_vm.userStats.total}})]},proxy:true}:null,(!_vm.loadingUserStats && !!_vm.userStats && !_vm.errorUserStats)?{key:"col-data-2",fn:function(){return [_c('div',{staticClass:"tw-text-center"},[_c('p',{staticClass:"tw-text-base tw-font-bold leading-19 tw-mt-4 tw-mx-4 tw-capitalize"},[_vm._v("New "+_vm._s(_vm.userTypeName)+"s")]),_c('StatsByRange',{staticClass:"tw-mt-3",attrs:{"range-container-class":"tw-px-4","ranges":['this week', 'this month'],"stats":[
          _vm.userStats.new.thisWeek,
          _vm.userStats.new.thisMonth ],"stats-types":[
          'line-calendar-weekly',
          'bar-calendar-monthly'
        ]}})],1)]},proxy:true}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }