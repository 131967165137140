<template>
  <div class="tw-rounded-10 tw-cursor-pointer hover:tw-bg-app-black-11">
    <!-- @click="" -->
    <UserPhoto
      :photo="user.photo"
      :user-name="user"
      :photo-size="userPhotoSize"
      :class="[
        userPhotoRadius,
        userPhotoClass,
        '--force-children'
      ]"
    />

    <p
      :class="[
        userNameFontClass,
        'tw-mt-2'
      ]"
    >
      {{ user.name }}
    </p>
    <p
      :class="[
        userNameFontClass,
      ]"
    >
      {{ user.middle || user.last_name }}
    </p>
  </div>
</template>

<script>
import UserPhoto from '@/components/UserPhoto.vue';

export default {
  name: 'UserVertical',
  components: {
    UserPhoto,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    userPhotoRadius: {
      type: String,
      default: 'tw-rounded-10--force',
    },
    userPhotoSize: {
      type: String,
      default: 'tw-w-8 tw-h-8',
    },
    userPhotoClass: {
      type: String,
      default: '',
    },
    userNameFontClass: {
      type: String,
      default: 'tw-text-xs leading-15',
    },
  },
  created() {
    if (!this.user.name || (!this.user.middle && !this.user.last_name)) {
      console.warn('Props data user{} missing some required data\n', this.user);
    }
  },
};
</script>

<style>

</style>
