<template>
  <div class="tw-relative tw-w-full">

    <portal to="top-app-view">
      <transition name="fade" mode="out-in" appear>
        <TopNav
          title="Goals Stats"
          center-title
          title-class="font-title tw-text-xl tw-tracking-wider tw-leading-6 opacity-54"
          :always-center="true"
        />
      </transition>
    </portal>

    <div class="tw-flex tw-flex-wrap tw-w-full px-body border-box tw-mt-10">

      <div class="tw-shrink-0 tw-relative">
        <StatsCard
          :class="[
            'stats-card--440 tw-mr-10 tw-mb-4 tw-bg-white tw-z-20',
            { '--loading-data': loadingTasksStats },
            { '--loading-data --loading-data--error': errorTasksStats },
          ]"
          :loading-msg="errorTasksStats ? errorTasksStats : 'Fetching Stats...'"
          style="min-height: 250px;"
        >

          <template v-if="!loadingTasksStats && !!tasksStats && !errorTasksStats" v-slot:col-data-1>

            <div class="tw-text-left">
              <p class="tw-text-base tw-font-bold leading-19 tw-mt-4 tw-mx-4">Tasks Created</p>
              <StatsByRange
                class="tw-mt-3 tw-mx-4"
                :ranges="['total', 'this week', 'this month']"
                :stats="[
                  tasksStats.total,
                  tasksStats.new.thisWeek,
                  tasksStats.new.thisMonth,
                ]"
                stats-type="number"
                range-container-class="tw-text-app-dark-yellow"
              />
            </div>

          </template>

          <template v-if="!loadingTasksStats && !!tasksStats && !errorTasksStats" v-slot:col-data-2>
            <!-- PIE CHART -->
            <div class="tw-flex tw-justify-around tw-items-center tw-bg-white tw-w-full tw-h-full">
              <PieChart
                :data="[
                  tasksStats.completedTasks,
                  tasksStats.ongoingTasks,
                  tasksStats.overdueTasks,
                ]"
                :data-labels="[
                  'Completed Tasks',
                  'Ongoing Tasks',
                  'Overdue Tasks',
                ]"
                :data-colors="[
                  '#000000',
                  '#EDA310',
                  '#E96B6B',
                ]"
                :height="190 + 24"
                :width="114 + 24"
              />
            </div>
          </template>

        </StatsCard>
      </div>

      <div class="tw-pl-2"></div>

      <!-- COMPLETED GOALS STATS -->

    </div>

    <div class="tw-relative tw-w-full px-body border-box tw-max-w-full">

      <StatsCard
        :class="[
          'stats-card--full tw-flex-col tw-bg-white tw-w-full tw-max-w-full border-box tw-mb-4',
          { '--loading-data tw-overflow-hidden': loadingGoalsStats },
          { '--loading-data --loading-data--error': errorGoalsStats },
        ]"
        :loading-msg="errorGoalsStats ? errorGoalsStats : 'Fetching Stats...'"
        style="min-height: 274px;"
      >
        <template v-if="!loadingGoalsStats && !errorGoalsStats">

          <div class="tw-flex tw-mt-4 tw-mx-4 tw-items-center">
            <p class="tw-text-base tw-font-bold leading-19">Goals Selected</p>
            <div class="tw-ml-12">
              <div class="tw-inline-flex tw-items-center">
                <span class="tw-rounded-full tw-bg-app-brown tw-flex-shrink-0" style="height: 10px; width: 10px;"></span>
                <span class="tw-block tw-w-full tw-text-center opacity-54 leading-14 tw-uppercase tw-text-xs tw-ml-2">Mentors</span>
              </div>
            </div>
          </div>
          <div class="tw-pb-6 tw-mt-6">
            <MyBarGraph
              :labels="selectedGoalsStats.labels || []"
              :data="selectedGoalsStats.data || []"
              height="188px"
            />

          </div>

        </template>
      </StatsCard>

      <StatsCard
        :class="[
          'stats-card--full tw-flex-col tw-bg-white tw-w-full tw-max-w-full border-box tw-mb-4',
          { '--loading-data tw-overflow-hidden': loadingSubGoalsStats },
          { '--loading-data --loading-data--error': errorSubGoalsStats },
        ]"
        :loading-msg="errorSubGoalsStats ? errorSubGoalsStats : 'Fetching Stats...'"
        style="min-height: 274px;"
      >
        <template v-if="!loadingSubGoalsStats && !errorSubGoalsStats">

          <div class="tw-flex tw-mt-4 tw-mx-4 tw-items-center">
            <p class="tw-text-base tw-font-bold leading-19">Sub-Goals Selected</p>
            <div class="tw-ml-12">
              <div class="tw-inline-flex tw-items-center">
                <span class="tw-rounded-full tw-bg-app-green tw-flex-shrink-0" style="height: 10px; width: 10px;"></span>
                <span class="tw-block tw-w-full tw-text-center opacity-54 leading-14 tw-uppercase tw-text-xs tw-ml-2">Mentees</span>
              </div>
            </div>
          </div>
          <div class="tw-pb-6 tw-mt-6">
            <MyBarGraph
              :labels="selectedSubGoalsStats.labels || []"
              :data="selectedSubGoalsStats.data || []"
              height="188px"
              bar-color="#BFD8BD"
            />

          </div>

        </template>
      </StatsCard>

    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import StatsCard from '@/components/StatsCard.vue';
import StatsByRange from '@/components/StatsByRange.vue';
import PieChart from '@/components/PieChart.vue';
import MyBarGraph from '@/components/MyBarGraph.vue';

export default {
  name: 'GoalsStats',
  components: {
    StatsCard,
    StatsByRange,
    PieChart,
    MyBarGraph,
  },
  data() {
    return {
      loadingTasksStats: true,
      errorTasksStats: true,

      loadingGoalsStats: true,
      errorGoalsStats: undefined,

      loadingSubGoalsStats: true,
      errorSubGoalsStats: undefined,

      selectedGoalsStats: {},
      selectedSubGoalsStats: {},
    };
  },
  computed: {
    ...mapGetters('GoalsStats', [
      'tasksStats',
    ]),
  },
  methods: {
    async getTasksStats() {
      this.loadingTasksStats = true;
      this.errorTasksStats = undefined;

      await this.$nextTick();
      const result = await this.$store.dispatch('GoalsStats/tasksStats')
        .catch(() => {
          this.$toasted.global.appError();
          this.errorTasksStats = 'ERROR';
          return false;
        });

      await this.$nextTick();

      if (result) {
        this.errorTasksStats = undefined;
      }
      this.loadingTasksStats = false;
    },
    /**
     *
     * @param {String} goalType Goals/SubGoals
     */
    async getSelectedGoalsStats(goalType = '') {
      this[`loading${goalType}Stats`] = true;
      this[`error${goalType}Stats`] = undefined;

      await this.$nextTick();
      await this.$store.dispatch(`GoalsStats/selected${goalType}Stats`)
        .catch((err) => {
          console.warn('selectedGoalsStats: \n', err);
          this.$toasted.global.appError();
          this[`error${goalType}Stats`] = 'ERROR';
          return false;
        })
        .then((stats) => {
          // console.log(stats);
          if (!stats) {
            this.$toasted.global.appError();
            this[`error${goalType}Stats`] = 'ERROR';
            return false;
          }

          this.errorRankingAccStats = undefined;
          this[`selected${goalType}Stats`] = stats;
          return stats;
        });

      await this.$nextTick();
      this[`loading${goalType}Stats`] = false;
    },
  },
  async created() {
    // get data
    await Promise.all([
      this.getTasksStats(),
      this.getSelectedGoalsStats('Goals'),
      this.getSelectedGoalsStats('SubGoals'),
    ]);

    // data loaded
    await this.$nextTick();

    // recalculate AppView
    this.$emit('mounted');
  },
};
</script>

<style>

</style>
