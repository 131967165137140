var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-rounded-10 tw-cursor-pointer hover:tw-bg-app-black-11"},[_c('UserPhoto',{class:[
      _vm.userPhotoRadius,
      _vm.userPhotoClass,
      '--force-children'
    ],attrs:{"photo":_vm.user.photo,"user-name":_vm.user,"photo-size":_vm.userPhotoSize}}),_c('p',{class:[
      _vm.userNameFontClass,
      'tw-mt-2'
    ]},[_vm._v(" "+_vm._s(_vm.user.name)+" ")]),_c('p',{class:[
      _vm.userNameFontClass ]},[_vm._v(" "+_vm._s(_vm.user.middle || _vm.user.last_name)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }