<template>
  <div
    :class="[
      'stats-card tw-flex tw-flex-no-wrap tw-items-stretch tw-relative tw-rounded-3 tw-shadow-app-sm',
    ]"
  >

    <slot name="default" />

    <transition name="fade" mode="out-in" appear>
      <div class="tw-bg-white tw-shrink-0 tw-rounded-l-3">
        <slot name="col-data-1" />
      </div>
    </transition>

    <transition name="fade" mode="out-in" appear>
      <div class="stats-card__col-data tw-flex-shrink-0 tw-bg-app-dark-yellow-11 tw-rounded-r-3">
        <slot name="col-data-2" />
      </div>
    </transition>

  </div>
</template>

<script>
export default {
  name: 'StatsCard',
  props: {},
};
</script>

<style>

</style>
