var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":_vm.chartId}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loadingData),expression:"!loadingData"}],staticClass:"tw-flex tw-flex-no-wrap tw-overflow-x-auto tw-pb-4 tw-items-end tw-pl-4 tw-box-content",style:({ height: _vm.height })},_vm._l((_vm.data),function(d,i){return _c('div',{key:i,class:[
        'tw-flex-shrink-0 tw-pr-4 transition-slow',
        { 'tw-flex-grow-0': _vm.isFixedCategoryWidth } ],style:({ width: _vm.categoryWidth })},[_c('span',{staticClass:"tw-block tw-max-w-full transition-slow font-nunito tw-w-8 tw-text-center leading-14 tw-text-xs tw-mb-2"},[_vm._v(" "+_vm._s(d)+" ")]),_c('span',{class:[
          'tw-block tw-max-w-full tw-w-8 transition-slow' ],style:({
          height: ((_vm.barHeights[i]) + "px"),
          backgroundColor: _vm.barColor,
        }),attrs:{"title":((_vm.labels[i]) + ": " + d)}}),_c('span',{class:[
          'tw-block tw-w-full tw-text-left opacity-54 tw-mt-2 tw-overflow-hidden tw-break-words',
          { 'tw-font-sans tw-leading-3 tw-text-xs': !_vm.labelClass },
          { labelClass: _vm.labelClass } ],staticStyle:{"height":"48px"},attrs:{"title":_vm.labels[i]}},[_vm._v(" "+_vm._s(_vm.labels[i])+" ")])])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }