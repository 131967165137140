<template>
  <div v-if="validRanges.length > 0" class="tw-relative">
    <v-select
      :class="[
        'my-select my-select--tiny tw-inline-block transition',
      ]"
      v-model.lazy="activeRangeId"
      :options="validRanges"
      :clearable="false"
      :searchable="false"
      :reduce="(range) => validRanges.indexOf(range)"
      transition="new"
    >
      <template v-slot:option="{ label }">
        <p class="tw-text-sm tw-text-left">{{ label }}</p>
      </template>
    </v-select>

    <template>
      <transition
        v-if="activeRangeComponent"
        name="fade"
        mode="out-in"
      >
        <keep-alive>
          <component
            :is="activeRangeComponent"
            :type="validStatsType"
            :data="activeStats"
            :data-options="activeRangeComponentOptions"
            v-bind="activeRangeComponentOptions.props"
            :class="['tw-mt-4 tw-mb-2 tw-w-full border-box', rangeContainerClass]"
          />
        </keep-alive>
      </transition>
    </template>
  </div>
</template>

<script>
import NumberGraph from '@/components/NumberGraph.vue';
import LineGraph from '@/components/LineGraph.vue';
import BarGraph from '@/components/BarGraph.vue';

export default {
  name: 'StatsByRange',
  components: {
    NumberGraph,
    LineGraph,
    BarGraph,
  },
  props: {
    ranges: {
      type: Array,
      required: true,
    },
    stats: {
      type: [Array, Object],
      required: true,
    },
    /**
     * @default {String} line
     * number,
     * line,
     * line-calendar-weekly,
     * line-calendar-monthly,
     * line-6-hours,
     * bar,
     * bar-calendar-weekly,
     * bar-calendar-monthly,
     * bar-6-hours,
     */
    statsTypes: {
      type: Array,
      required: false,
    },
    statsType: {
      type: String,
      default: 'line',
    },
    activeRange: {
      type: Number,
      default: 0,
    },
    rangeContainerClass: {
      type: [String, Array],
      default: '',
    },
  },
  data() {
    return {
      activeRangeId: this.activeRange,
    };
  },
  watch: {
    activeRangeId() {

    },
  },
  computed: {
    activeStats() {
      return this.stats[this.activeRangeId];
    },
    // returns only until the length equals the stats length
    validRanges() {
      return this.ranges.slice(0, this.stats.length);
    },
    validStatsType() {
      return (typeof this.statsTypes === 'object') ? this.statsTypes[this.activeRangeId] : this.statsType;
    },
    activeRangeComponent() {
      if (this.validStatsType === 'number') return 'NumberGraph';
      if (/(line)/g.test(this.validStatsType)) return 'LineGraph';
      if (/(bar)/g.test(this.validStatsType)) return 'BarGraph';

      return false;
    },
    activeRangeComponentOptions() {
      if (this.validStatsType === 'number') return '';
      if (/(line)/g.test(this.validStatsType)) return this.lineGraphOptions;
      if (/(bar)/g.test(this.validStatsType)) return this.barGraphOptions;

      return {};
    },
    lineGraphOptions() {
      return {
        maintainAspectRatio: true,
        aspectRatio: 0.356,
        layout: {
          padding: {
            // to avoid hiding the points at the top
            top: 8,
          },
        },
        props: {
          // width: null,
          height: 180,
        },
      };
    },
    barGraphOptions() {
      return {
        maintainAspectRatio: true,
        aspectRatio: 0.356,
        props: {
          // width: null,
          height: 180,
        },
      };
    },
  },
  methods: {
  },
  created() {
    // set activeRangeId

  },
};
</script>

<style>

</style>
