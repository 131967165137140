<template>
  <div>
    <ContentLoader
      :width="760"
      :height="80"
      :speed="2"
      :class="[
        'transform-scale-0',
        { 'tw-mb-4': listLength > 1 },
        listClasses[i] || listClasses[3]
      ]"
      v-for="(list, i) in lists"
      :key="i"
      primaryColor="#f3f3f3"
      secondaryColor="#ecebeb"
    >
      <rect x="20" y="0" rx="0" ry="0" width="720" height="80" />
    </ContentLoader>
  </div>
</template>

<script>
import { ContentLoader } from 'vue-content-loader';

export default {
  name: 'ContentLoadingFullWidth',
  components: {
    ContentLoader,
  },
  props: {
    listLength: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      lists: Array(this.listLength),
      listClasses: ['opacity-100', 'opacity-78', 'opacity-31', 'opacity-11'],
    };
  },
};
</script>

<style>

</style>
