<template>
  <div>

    <portal to="top-app-view">
      <transition name="fade" mode="out-in" appear>
        <TopNav
          title="Accounts Stats"
          center-title
          title-class="font-title tw-text-xl tw-tracking-wider tw-leading-6 opacity-54"
          :always-center="true"
        />
      </transition>
    </portal>

    <div class="tw-flex tw-flex-wrap tw-w-full px-body border-box tw-mt-10">

      <div class="tw-shrink-0 tw-relative">
        <UserStatsCard
          :user-type-id="accountTypes.mentor"
          class="stats-card--392 tw-mr-4 tw-mb-4 tw-bg-white tw-z-20"
        />

        <UserStatsCard
          :user-type-id="accountTypes.mentee"
          class="stats-card--392 tw-mr-4 tw-mb-4 tw-bg-white tw-z-10"
          range-container-class="tw-px-4"
        />
      </div>

      <div class="tw-mb-4 tw-mr-4 tw-pb-2 tw-ml-2 tw-flex-grow" style="min-height: 173px;">
        <!-- DORMANT ACCOUNTS -->
        <p class="tw-text-base tw-font-bold leading-19 tw-mb-3 tw-mx-4">
          Dormant Accounts
        </p>

        <div v-if="errorDormantAccStats" class="tw-mt-12">
          <p class="tw-py-12 tw-text-base tw-text-center" v-html="errorDormantAccStats"></p>
        </div>

        <div v-else-if="loadingDormantAccStats" class="tw-mt-12">
          <p class="tw-py-12 tw-text-base tw-text-center">Loading account stats...</p>
        </div>

        <template v-else>
          <div class="tw-inline-flex tw-flex-col">
            <div class="tw-inline-flex tw-justify-start">
              <div class="hover:tw-bg-app-white-32 tw-px-4 tw-py-4 tw-group">
                <div class="tw-inline-flex tw-flex-col">
                  <div class="tw-inline-flex tw-items-center">
                    <NumberGraph
                      class="tw-text-red-500 "
                      :data="dormantAccounts.mentors"
                    />
                    <p class="tw-text-red-500 tw-text-base leading-19 tw-ml-2">
                      Mentors
                    </p>
                  </div>
                  <BaseButton
                    class="tw-opacity-0 group-hover:tw-opacity-100 transition"
                    text="View Mentors"
                    :to="{
                      name: 'accounts',
                      query: {
                        'type': 'mentor',
                        'state': 'dormant',
                      },
                    }"
                  />
                </div>
              </div>
              <div class="tw-ml-12 hover:tw-bg-app-white-32 tw-group tw-px-4 tw-py-4">
                <div class="tw-inline-flex tw-flex-col">
                  <div class="tw-inline-flex tw-items-center">
                    <NumberGraph
                      class="tw-text-red-500 "
                      :data="dormantAccounts.mentees"
                    />
                    <p class="tw-text-red-500 tw-text-base leading-19 tw-ml-2">
                      Mentees
                    </p>
                  </div>
                  <BaseButton
                    class="tw-opacity-0 group-hover:tw-opacity-100 transition"
                    text="View Mentees"
                    :to="{
                      name: 'accounts',
                      query: {
                        'type': 'mentee',
                        'state': 'dormant',
                      },
                    }"
                  />
                </div>
              </div>
            </div>
            <div class="tw-inline-flex tw-mt-4 tw-justify-start">
              <div class="hover:tw-bg-app-white-32 tw-px-4 tw-py-4 tw-group">
                <div class="tw-inline-flex tw-flex-col">
                  <div class="tw-inline-flex tw-items-center">
                    <NumberGraph
                      class="tw-text-red-500 "
                      :data="dormantAccounts.staff"
                    />
                    <p class="tw-text-red-500 tw-text-base leading-19 tw-ml-2">
                      Staff
                    </p>
                  </div>
                  <BaseButton
                    class="tw-opacity-0 group-hover:tw-opacity-100 transition"
                    text="View Staff"
                    :to="{
                      name: 'accounts',
                      query: {
                        'type': 'staff',
                        'state': 'dormant',
                      },
                    }"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>

      </div>

    </div>

    <div class="tw-w-full px-body border-box">

      <StatsCard
        :class="[
          'stats-card--full tw-flex-col tw-pb-2 tw-bg-white',
          { '--loading-data': loadingRankingAccStats },
          { '--loading-data --loading-data--error': errorRankingAccStats },
        ]"
        :loading-msg="errorRankingAccStats ? errorRankingAccStats : 'Fetching Rankings...'"
        style="min-height: 173px;"
      >
        <template v-if="!loadingRankingAccStats && !errorRankingAccStats">

          <!-- TOP MENTORS -->
          <div class="tw-mt-4 tw-mb-3 tw-ml-6 tw-font-bold tw-text-base leading-19">Top Mentors</div>
          <ContentLoadingTopUsers style="width: 400px;" class="tw-mx-6" v-if="!topMentors.length"/>

          <div v-else class="tw-mb-2 tw-max-w-full tw-inline-flex tw-flex-no-wrap tw-mx-auto">
            <UserVertical
              v-for="(user, index) in topMentors"
              :key="index"
              :class="[
                'tw-px-2 tw-py-1 tw-text-center transition-slow tw-flex-grow-0 tw-flex-shrink-0',
                { 'tw-mr-8': (index + 1) !== topMentors.length },
              ]"
              :title="`${user.name}`"
              :user="user"
              userPhotoRadius="tw-rounded-10--force"
              userPhotoSize="w-56 h-56"
              userPhotoClass="tw-mx-auto"
              userNameFontClass="tw-text-sm tw-font-sans leading-17"
            />
          </div>

          <!-- TOP MENTEES -->
          <div class="tw-mt-4 tw-mb-3 tw-ml-6 tw-font-bold tw-text-base leading-19">Top Mentees</div>
          <ContentLoadingTopUsers style="width: 400px;" class="tw-mx-6" v-if="!topMentees.length"/>

          <div v-else class="tw-mb-2 tw-max-w-full tw-inline-flex tw-flex-no-wrap tw-mx-auto">
            <UserVertical
              v-for="(user, index) in topMentees"
              :key="index"
              :class="[
                'tw-px-2 tw-py-1 tw-text-center transition-slow tw-flex-grow-0 tw-flex-shrink-0',
                { 'tw-mr-8': (index + 1) !== topMentees.length },
              ]"
              :title="`${user.name}`"
              :user="user"
              userPhotoRadius="tw-rounded-10--force"
              userPhotoSize="w-56 h-56"
              userPhotoClass="tw-mx-auto"
              userNameFontClass="tw-text-sm tw-font-sans leading-17"
            />
          </div>

        </template>
      </StatsCard>

    </div>

  </div>
</template>

<script>
import UserStatsCard from '@/views/_partials/UserStatsCard.vue';
import StatsCard from '@/components/StatsCard.vue';
import NumberGraph from '@/components/NumberGraph.vue';
import UserVertical from '@/components/UserVertical.vue';
import ContentLoadingTopUsers from '@/components/ContentLoadingTopUsers.vue';

export default {
  name: 'AccountsStats',
  components: {
    UserStatsCard,
    StatsCard,
    NumberGraph,
    UserVertical,
    ContentLoadingTopUsers,
  },
  data() {
    return {
      loadingRankingAccStats: true,
      loadingDormantAccStats: true,

      errorRankingAccStats: undefined,
      errorDormantAccStats: undefined,

      topMentors: [],
      topMentees: [],
      dormantAccounts: {},
    };
  },
  computed: {
    accountTypes() {
      return this.$store.state.User.accountTypes;
    },
  },
  methods: {
    async getTopAccStats(userType = '', fetchNew = true) {
      this.loadingRankingAccStats = true;
      this.errorRankingAccStats = undefined;

      await this.$nextTick();
      await this.$store.dispatch('AccountsStats/topUserTypes', [userType, fetchNew])
        .catch((err) => {
          console.warn('topUserTypes', err);
          this.$toasted.global.appError();
          this.errorRankingAccStats = 'ERROR';
        })
        .then((stats) => {
          if (!stats) {
            this.$toasted.global.appError();
            this.errorRankingAccStats = 'ERROR';
            return false;
          }

          this.errorRankingAccStats = undefined;
          this[`top${userType}`] = stats;
          return stats;
        });

      await this.$nextTick();
      this.loadingRankingAccStats = false;
    },
    async getDormantAccStats(fetchNew = true) {
      this.loadingDormantAccStats = true;
      this.errorDormantAccStats = undefined;

      await this.$nextTick();
      await this.$store.dispatch('AccountsStats/dormantAccountsStats', fetchNew)
        .catch((err) => {
          console.warn('topUserTypes', err);
          this.$toasted.global.appError();
          this.errorDormantAccStats = 'ERROR';
        })
        .then((stats) => {
          if (!stats) {
            this.$toasted.global.appError();
            this.errorDormantAccStats = 'ERROR';
            return false;
          }

          this.errorDormantAccStats = undefined;
          this.dormantAccounts = stats;
          return stats;
        });

      await this.$nextTick();
      this.loadingDormantAccStats = false;
    },
  },
  async created() {
    // get account stats
    await Promise.all([
      this.getTopAccStats('Mentors'),
      this.getTopAccStats('Mentees'),
      this.getDormantAccStats(),
    ]);

    // recalculate AppView
    this.$emit('mounted');

    // all data is loaded
  },
};
</script>

<style>

</style>
