<template>
  <VLineChart
    :chart-data="chartData"
    :options="{ ...mergedOptions }"
    v-bind="propsData"
  />
</template>

<script>
import VLineChart from '@/components/vLineChart';
import DateMixin from '@/mixins/date';

export default {
  name: 'LineGraph',
  components: {
    VLineChart,
  },
  mixins: [DateMixin],
  props: {
    data: {
      type: Array,
      required: true,
    },
    dataOptions: {
      type: Object,
      default() {
        return {};
      },
    },
    datasetOptions: {
      type: Object,
      default() {
        return {};
      },
    },
    /**
     * @default {String} line
     * line
     * line-calendar-weekly
     * line-calendar-monthly
     * line-6-hours
     */
    type: {
      type: String,
      default: 'line',
    },
    lineThickness: {
      type: Number,
      default: 2,
    },
    displayYAxes: {
      type: [Boolean, String],
      default: false,
    },
    displayXAxes: {
      type: [Boolean, String],
      default: 'auto',
    },
  },
  computed: {
    propsData() {
      return this.dataOptions.props || {};
    },
    mergedOptions() {
      return { ...this.defaultOptions, ...this.dataOptions };
    },
    defaultOptions() {
      return {
        responsive: true,
        aspectRatio: 0.75,
        showLines: false,
        legend: {
          display: false,
        },
        scales: {
          yAxes: [{
            display: this.displayYAxes,
            type: 'linear',
            gridLines: {
              display: false,
            },
            ticks: {
              display: false,
            },
          }],
          xAxes: [{
            display: (this.displayXAxes && this.type !== 'line'),
            gridLines: {
              display: false,
            },
            ticks: {
              display: this.displayXAxes,
            },
          }],
        },
      };
    },
    mergedDatasetOptions() {
      return { ...this.defaultDatasetOptions, ...this.dataSetOptions };
    },
    defaultDatasetOptions() {
      return {
        backgroundColor: 'rgba(178, 150, 125, 0.11)',
        borderColor: '#B2967D',
        borderCapStyle: 'round',
        borderWidth: 2,
        showLine: true,
        pointBackgroundColor: '#B2967D',
        pointBorderWidth: 0,
        lineTension: 0.2,
      };
    },
    chartLabels() {
      let labels = Array(this.data.length).join('.').split('.');
      switch (this.type) {
        case 'line-calendar-weekly':
          labels = this.daysOfTheWeek;
          break;
        case 'line-calendar-monthly':
          labels = this.daysOfTheMonth;
          break;
        case 'line-6-hours':
          labels = this.lastSixHours;
          break;
        default:
          break;
      }

      return labels;
    },
    chartData() {
      return {
        labels: this.chartLabels,
        datasets: [
          {
            data: this.data,
            ...this.mergedDatasetOptions,
          },
        ],
      };
    },
  },
};
</script>

<style>

</style>
