<template>
  <VBarChart
    :chart-data="chartData"
    :options="{ ...mergedOptions }"
    v-bind="propsData"
  />
</template>

<script>
import VBarChart from '@/components/vBarChart';
import DateMixin from '@/mixins/date';

export default {
  name: 'BarGraph',
  components: {
    VBarChart,
  },
  mixins: [DateMixin],
  props: {
    data: {
      type: Array,
      required: true,
    },
    dataOptions: {
      type: Object,
      default() {
        return {};
      },
    },
    /**
     * @default {String} bar
     * bar
     * bar-calendar-weekly
     * bar-calendar-monthly
     * bar-6-hours
     */
    type: {
      type: String,
      default: 'bar',
    },
    maxBarThickness: {
      type: Number,
      default: 32,
    },
    barBackGroundColour: {
      type: String,
      default: '#B2967D',
    },
    displayYAxes: {
      type: [Boolean, String],
      default: false,
    },
    displayXAxes: {
      type: [Boolean, String],
      default: true,
    },
  },
  computed: {
    propsData() {
      return this.dataOptions.props || {};
    },
    mergedOptions() {
      return { ...this.defaultOptions, ...this.dataOptions };
    },
    defaultOptions() {
      return {
        responsive: true,
        aspectRatio: 0.75,
        showLines: false,
        legend: {
          display: false,
        },
        scales: {
          yAxes: [{
            display: this.displayYAxes,
            type: 'linear',
            gridLines: {
              display: false,
            },
            ticks: {
              display: false,
            },
          }],
          xAxes: [{
            display: this.displayXAxes,
            gridLines: {
              display: false,
            },
            ticks: {
              display: this.displayXAxes,
            },
          }],
        },
      };
    },
    chartLabels() {
      let labels = Array(this.data.length).join('.').split('.');
      switch (this.type) {
        case 'bar-calendar-weekly':
          labels = this.daysOfTheWeek;
          break;
        case 'bar-calendar-monthly':
          labels = this.daysOfTheMonth;
          break;
        case 'bar-6-hours':
          labels = this.lastSixHours;
          break;
        default:
          break;
      }

      return labels;
    },

    chartData() {
      return {
        labels: this.chartLabels,
        datasets: [
          {
            data: this.data,
            backgroundColor: this.barBackGroundColour,
            maxBarThickness: this.maxBarThickness,
            minBarLength: 1,
          },
        ],
      };
    },
  },
};
</script>

<style>

</style>
