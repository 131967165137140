<template>
  <h2 class="tw-text-5xl tw-leading-tight font-nunito">
    {{ data | commafy }}
  </h2>
</template>

<script>
export default {
  name: 'NumberGraph',
  props: {
    data: {
      type: Number,
      required: true,
    },
  },
  filters: {
    commafy(val) {
      return val.toLocaleString();
    },
  },
};
</script>

<style>

</style>
