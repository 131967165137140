var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-relative tw-w-full"},[_c('portal',{attrs:{"to":"top-app-view"}},[_c('transition',{attrs:{"name":"fade","mode":"out-in","appear":""}},[_c('TopNav',{attrs:{"title":"Goals Stats","center-title":"","title-class":"font-title tw-text-xl tw-tracking-wider tw-leading-6 opacity-54","always-center":true}})],1)],1),_c('div',{staticClass:"tw-flex tw-flex-wrap tw-w-full px-body border-box tw-mt-10"},[_c('div',{staticClass:"tw-shrink-0 tw-relative"},[_c('StatsCard',{class:[
          'stats-card--440 tw-mr-10 tw-mb-4 tw-bg-white tw-z-20',
          { '--loading-data': _vm.loadingTasksStats },
          { '--loading-data --loading-data--error': _vm.errorTasksStats } ],staticStyle:{"min-height":"250px"},attrs:{"loading-msg":_vm.errorTasksStats ? _vm.errorTasksStats : 'Fetching Stats...'},scopedSlots:_vm._u([(!_vm.loadingTasksStats && !!_vm.tasksStats && !_vm.errorTasksStats)?{key:"col-data-1",fn:function(){return [_c('div',{staticClass:"tw-text-left"},[_c('p',{staticClass:"tw-text-base tw-font-bold leading-19 tw-mt-4 tw-mx-4"},[_vm._v("Tasks Created")]),_c('StatsByRange',{staticClass:"tw-mt-3 tw-mx-4",attrs:{"ranges":['total', 'this week', 'this month'],"stats":[
                _vm.tasksStats.total,
                _vm.tasksStats.new.thisWeek,
                _vm.tasksStats.new.thisMonth ],"stats-type":"number","range-container-class":"tw-text-app-dark-yellow"}})],1)]},proxy:true}:null,(!_vm.loadingTasksStats && !!_vm.tasksStats && !_vm.errorTasksStats)?{key:"col-data-2",fn:function(){return [_c('div',{staticClass:"tw-flex tw-justify-around tw-items-center tw-bg-white tw-w-full tw-h-full"},[_c('PieChart',{attrs:{"data":[
                _vm.tasksStats.completedTasks,
                _vm.tasksStats.ongoingTasks,
                _vm.tasksStats.overdueTasks ],"data-labels":[
                'Completed Tasks',
                'Ongoing Tasks',
                'Overdue Tasks' ],"data-colors":[
                '#000000',
                '#EDA310',
                '#E96B6B' ],"height":190 + 24,"width":114 + 24}})],1)]},proxy:true}:null],null,true)})],1),_c('div',{staticClass:"tw-pl-2"})]),_c('div',{staticClass:"tw-relative tw-w-full px-body border-box tw-max-w-full"},[_c('StatsCard',{class:[
        'stats-card--full tw-flex-col tw-bg-white tw-w-full tw-max-w-full border-box tw-mb-4',
        { '--loading-data tw-overflow-hidden': _vm.loadingGoalsStats },
        { '--loading-data --loading-data--error': _vm.errorGoalsStats } ],staticStyle:{"min-height":"274px"},attrs:{"loading-msg":_vm.errorGoalsStats ? _vm.errorGoalsStats : 'Fetching Stats...'}},[(!_vm.loadingGoalsStats && !_vm.errorGoalsStats)?[_c('div',{staticClass:"tw-flex tw-mt-4 tw-mx-4 tw-items-center"},[_c('p',{staticClass:"tw-text-base tw-font-bold leading-19"},[_vm._v("Goals Selected")]),_c('div',{staticClass:"tw-ml-12"},[_c('div',{staticClass:"tw-inline-flex tw-items-center"},[_c('span',{staticClass:"tw-rounded-full tw-bg-app-brown tw-flex-shrink-0",staticStyle:{"height":"10px","width":"10px"}}),_c('span',{staticClass:"tw-block tw-w-full tw-text-center opacity-54 leading-14 tw-uppercase tw-text-xs tw-ml-2"},[_vm._v("Mentors")])])])]),_c('div',{staticClass:"tw-pb-6 tw-mt-6"},[_c('MyBarGraph',{attrs:{"labels":_vm.selectedGoalsStats.labels || [],"data":_vm.selectedGoalsStats.data || [],"height":"188px"}})],1)]:_vm._e()],2),_c('StatsCard',{class:[
        'stats-card--full tw-flex-col tw-bg-white tw-w-full tw-max-w-full border-box tw-mb-4',
        { '--loading-data tw-overflow-hidden': _vm.loadingSubGoalsStats },
        { '--loading-data --loading-data--error': _vm.errorSubGoalsStats } ],staticStyle:{"min-height":"274px"},attrs:{"loading-msg":_vm.errorSubGoalsStats ? _vm.errorSubGoalsStats : 'Fetching Stats...'}},[(!_vm.loadingSubGoalsStats && !_vm.errorSubGoalsStats)?[_c('div',{staticClass:"tw-flex tw-mt-4 tw-mx-4 tw-items-center"},[_c('p',{staticClass:"tw-text-base tw-font-bold leading-19"},[_vm._v("Sub-Goals Selected")]),_c('div',{staticClass:"tw-ml-12"},[_c('div',{staticClass:"tw-inline-flex tw-items-center"},[_c('span',{staticClass:"tw-rounded-full tw-bg-app-green tw-flex-shrink-0",staticStyle:{"height":"10px","width":"10px"}}),_c('span',{staticClass:"tw-block tw-w-full tw-text-center opacity-54 leading-14 tw-uppercase tw-text-xs tw-ml-2"},[_vm._v("Mentees")])])])]),_c('div',{staticClass:"tw-pb-6 tw-mt-6"},[_c('MyBarGraph',{attrs:{"labels":_vm.selectedSubGoalsStats.labels || [],"data":_vm.selectedSubGoalsStats.data || [],"height":"188px","bar-color":"#BFD8BD"}})],1)]:_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }