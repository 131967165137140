export default {
  name: 'DateMixin',
  computed: {
    daysOfTheWeek() {
      // return ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Friday', 'Sat'];
      return this.$moment.weekdaysShort(true);
    },
    daysOfTheMonth() {
      const today = new Date();
      const currYear = today.getFullYear();
      const dates = [];

      const startOfMonth = new Date(currYear, today.getMonth(), 1);

      while (startOfMonth.getMonth() === today.getMonth()) {
        dates.push(this.$moment(startOfMonth).format('Do'));

        // go to next day date
        startOfMonth.setDate(startOfMonth.getDate() + 1);
      }

      return dates;
    },
    lastSixHours() {
      const currHour = new Date().getHours();
      const hours = [];

      for (let hr = currHour - 5; hr <= currHour; hr += 1) {
        hours.push(`${hr}${hr < 12 ? 'AM' : 'PM'}`);
      }

      return hours;
    },
  },
};
