<template>
  <div
    :id="chartId"
  >
    <div
      class="tw-flex tw-flex-no-wrap tw-overflow-x-auto tw-pb-4 tw-items-end tw-pl-4 tw-box-content"
      :style="{ height: height }"
      v-show="!loadingData"
    >
      <div
        v-for="(d, i) in data"
        :key="i"
        :class="[
          'tw-flex-shrink-0 tw-pr-4 transition-slow',
          { 'tw-flex-grow-0': isFixedCategoryWidth },
        ]"
        :style="{ width: categoryWidth }"
      >

        <span
          class="tw-block tw-max-w-full transition-slow font-nunito tw-w-8 tw-text-center leading-14 tw-text-xs tw-mb-2"
        >
          {{ d }}
        </span>

        <span
          :class="[
            'tw-block tw-max-w-full tw-w-8 transition-slow',
          ]"
          :style="{
            height: `${barHeights[i]}px`,
            backgroundColor: barColor,
          }"
          :title="`${labels[i]}: ${d}`"
        ></span>

        <span
          :class="[
            'tw-block tw-w-full tw-text-left opacity-54 tw-mt-2 tw-overflow-hidden tw-break-words',
            { 'tw-font-sans tw-leading-3 tw-text-xs': !labelClass },
            { labelClass: labelClass },
          ]"
          :title="labels[i]"
          style="height: 48px;"
        >
          {{ labels[i] }}
        </span>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MyBarGraph',
  props: {
    data: {
      type: Array,
      required: true,
    },
    labels: {
      type: Array,
      required: true,
    },
    /**
     * Can be in px or %
     */
    height: {
      type: String,
      default: '100%',
    },
    categoryWidth: {
      type: [Number, String],
      default: '80px',
    },
    barColor: {
      type: [String, Array],
      default: '#B2967D',
    },
    labelClass: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      barHeights: [],
      chartId: undefined,
      loadingData: true,
    };
  },
  computed: {
    isFixedCategoryWidth() {
      return this.categoryWidth === 'auto';
    },
  },
  watch: {
    data() {
      this.loadingData = true;
      this.calculateBarHeights();
      this.loadingData = false;
    },
  },
  methods: {
    calculateBarHeights() {
      // get the highest data
      const highestData = Math.max(...(this.data || [0]));

      // h - 22px(left for the data on top of the bar) - 40px(left for the labels)
      const calculateMaxBarHeight = () => {
        let height = Number(this.height.split(/\D+/).join(''));

        // if the height prop was in %
        if (/[%]/.test(this.height) && this.chartId) {
          const wrapperParentHeight = document.getElementById(this.chartId).parentElement.innerHeight;
          const pHeight = Math.round((height * wrapperParentHeight) / 100);
          height = (pHeight > 0) ? pHeight : height;
        }

        // height - (height for the data of each bar) - (height for the labels for each bar) - (bottom padding of the wrapper element(.tw-pb-4))
        return height - 22 - 60 - 16;
      };
      const maxBarHeight = calculateMaxBarHeight();

      const calculateDataHeight = (data) => {
        const height = Math.floor((data * maxBarHeight) / highestData);
        return height <= 0 ? 1 : height;
      };


      for (let i = 0; i < this.data.length; i += 1) {
        this.barHeights[i] = calculateDataHeight(this.data[i]);
      }
    },
  },
  created() {
    // generate an id for the wrapper
    this.chartId = `mbc-${Math.random().toString(36).substr(2, 4)}`;

    this.loadingData = true;

    this.calculateBarHeights();

    this.loadingData = false;
  },
};
</script>

<style>

</style>
