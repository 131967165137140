<template>
  <div>

    <portal to="top-app-view">
      <transition name="fade" mode="out-in" appear>
        <TopNav
          title="Matches Stats"
          center-title
          title-class="font-title tw-text-xl tw-tracking-wider tw-leading-6 opacity-54"
          :always-center="true"
        />
      </transition>
    </portal>

    <transition name="fade" mode="out-in" appear>
      <div v-if="errorMatchesStats">
        <p class="tw-text-xs opacity-78 tw-text-center tw-py-12" v-html="errorMatchesStats"></p>
      </div>
      <div v-else-if="loadingMatchesStats">
        <p class="tw-text-xs opacity-78 tw-text-center tw-py-12">Loading...</p>
      </div>
      <div
        v-else
        class="tw-mt-12 tw-flex tw-flex-col tw-justify-center"
      >
        <div
          class="tw-inline-flex tw-flex-shrink tw-flex-grow-0 tw-mx-auto tw-items-stretch tw-text-center tw-justify-center tw-border-app-black-11"
          style="min-width: 320px; min-height: 120px; max-width: 500px;"
        >

          <div class="tw-flex tw-w-1/2 tw-flex-col tw-justify-between tw-pr-4 tw-pb-4 tw-border-b tw-border-r tw-border-app-black-11">
            <p class="tw-text-base tw-font-bold leading-19 tw-mt-4 tw-mx-4" style="max-width: 132px;">
              Total Matches
            </p>
            <NumberGraph
              class="tw-text-app-dark-yellow tw-mt-3 tw-mx-4"
              :data="matchStats.total"
            />
          </div>
          <div class="tw-flex tw-w-1/2 tw-flex-col tw-justify-between tw-pl-4 tw-pb-4 tw-border-b">
            <p class="tw-text-base tw-font-bold leading-19 tw-mt-4 tw-mx-4" style="max-width: 132px;">
              Pending Match requests
            </p>
            <NumberGraph
              class="tw-text-app-dark-yellow tw-mt-3 tw-mx-4"
              :data="matchStats.pending"
            />
          </div>

        </div>
        <div
          class="tw-inline-flex tw-flex-shrink tw-flex-grow-0 tw-mx-auto tw-items-stretch tw-text-center tw-justify-center"
          style="min-width: 320px; min-height: 120px; max-width: 500px;"
        >

          <div class="tw-flex tw-w-1/2 tw-flex-col tw-justify-between tw-pr-4 tw-pb-4 tw-border-r tw-border-app-black-11">
            <p class="tw-text-base tw-font-bold leading-19 tw-mt-4 tw-mx-4" style="max-width: 132px;">
              Declined requests
            </p>
            <NumberGraph
              class="tw-text-app-dark-yellow tw-mt-3 tw-mx-4"
              :data="matchStats.declines"
            />
          </div>
          <div class="tw-flex tw-w-1/2 tw-flex-col tw-justify-between tw-pl-4 tw-pb-4">
            <p class="tw-text-base tw-font-bold leading-19 tw-mt-4 tw-mx-4" style="max-width: 132px;">
              Ended Matches
            </p>
            <NumberGraph
              class="tw-text-app-dark-yellow tw-mt-3 tw-mx-4"
              :data="matchStats.ended"
            />
          </div>

        </div>

      </div>
    </transition>

    <div class="tw-mt-16" v-if="false">

      <!-- DELAYED MATCH REQUESTS -->
      <p class="tw-text-base tw-font-bold leading-19 tw-text-center tw-mb-6 tw-mx-4">
        Delayed Match Requests
      </p>

      <transition mode="out-in" name="fade" appear>

        <div v-if="errorDelayedMatches">
          <p class="tw-text-xs opacity-78 tw-text-center tw-pb-12" v-html="errorDelayedMatches"></p>
        </div>

        <ContentLoadingFullWidth
          v-else-if="loadingDelayedMatches"
          class="tw-pb-12 tw-max-w-list tw-mx-auto"
          :list-length="4"
        />

        <div v-else-if="pendingMatchRequests.length === 0">
          <p class="tw-text-xs opacity-78 tw-text-center">All requests are decided on time!</p>
        </div>

        <template v-else>

          <MatchesList
            class="tw-max-w-list tw-mx-auto"
            :list="pendingMatchRequests"
            delayed-requests
          />

        </template>

      </transition>

    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import NumberGraph from '@/components/NumberGraph.vue';
import ContentLoadingFullWidth from '@/components/ContentLoadingFullWidth.vue';
import MatchesList from '@/views/_partials/MatchesList.vue';

export default {
  name: 'MatchesStats',
  components: {
    NumberGraph,
    MatchesList,
    ContentLoadingFullWidth,
  },
  data() {
    return {
      loadingMatchesStats: true,
      loadingDelayedMatches: true,

      errorMatchesStats: undefined,
      errorDelayedMatches: undefined,

      pendingMatchRequests: [],
    };
  },
  computed: {
    ...mapGetters('MatchesStats', [
      'matchStats',
    ]),
  },
  methods: {
    async getMatchStats() {
      this.loadingMatchesStats = true;
      this.errorMatchesStats = undefined;

      await this.$nextTick();
      const result = await this.$store.dispatch('MatchesStats/matchStats')
        .catch((err) => {
          console.warn('getMatchStats', err);
          this.$toasted.global.appError();
          this.errorMatchesStats = 'ERROR';
          return false;
        });

      await this.$nextTick();

      if (result) {
        this.errorMatchesStats = undefined;
      }
      this.loadingMatchesStats = false;
    },
    async getDelayedRequests() {
      this.loadingDelayedMatches = true;
      this.errorDelayedMatches = undefined;

      await this.$nextTick();
      await this.$store.dispatch('MatchesStats/delayedMatchRequests')
        .catch((err) => {
          console.warn('getDelayedRequests', err);
          this.$toasted.global.appError();
          this.errorDelayedMatches = 'ERROR';
        })
        .then((stats) => {
          if (!stats) {
            this.$toasted.global.appError();
            this.errorDelayedMatches = 'ERROR';
            return false;
          }

          this.errorDelayedMatches = undefined;
          this.pendingMatchRequests = stats;
          return stats;
        });

      await this.$nextTick();
      this.loadingDelayedMatches = false;
    },
  },
  async created() {
    // get match stats

    await Promise.all([
      this.getMatchStats(),
      // this.getDelayedRequests(),
    ]);

    // all data is loaded

    // recalculate AppView
    this.$emit('mounted');
  },
};
</script>

<style>

</style>
