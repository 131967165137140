<template>
  <ViewMore
    class=""
    :list="list"
    :limit="12"
  >

    <template v-slot="{ item: match, index }">
      <!-- { 'tw-bg-app-white-32': (((index % 2) !== 0) || index === 0) } -->
      <div
        :key="`${match.id || index}-${match.mentor.id}-${match.mentee.id}`"
        :class="[
          'tw-flex tw-items-center tw-justify-between tw-py-8 transition tw-border-b tw-border-app-black-11 tw-group',
        ]"
      >
        <div class="tw-inline-flex tw-pl-4">
          <div class="tw-mr-2">
            <!-- MENTOR -->
            <p class="tw-uppercase tw-font-bold tw-text-xs tw-mb-1 tw-px-2">MENTOR</p>
            <div class="tw-flex tw-pr-12 tw-border-r tw-border-app-black-11 tw-overflow-hidden" style="max-width: 220px">
              <UserVertical
                class="tw-px-2 tw-py-1 tw-text-left transition-slow tw-flex-grow-0 tw-flex-shrink-0"
                :title="`mentor: ${match.mentor.name}`"
                :user="match.mentor"
                :key="match.mentor.id"
                userPhotoRadius="tw-rounded-3--force"
                userPhotoSize="tw-w-8 tw-h-8"
                userNameFontClass="tw-text-xs leading-15"
              />

              <div>
                <p class="tw-text-xs tw-px-2 opacity-54 tw-truncate">
                  {{match.mentor.email}}
                  <span class="tw-ml-1"></span>
                </p>
                <p class="tw-text-xs tw-px-2 opacity-54">
                  {{match.mentor.working_num}}
                  <span class="tw-ml-1"></span>
                </p>
              </div>
            </div>
          </div>

          <div class="tw-pl-12">
            <!-- MENTEE -->
            <p class="tw-uppercase tw-font-bold tw-text-xs tw-mb-1 tw-px-2">MENTEE</p>
            <div class="tw-flex tw-pr-12-- tw-border-r-- tw-border-app-black-11 tw-overflow-hidden" style="max-width: 220px">
              <UserVertical
                class="tw-px-2 tw-py-1 tw-text-left transition-slow tw-flex-grow-0 tw-flex-shrink-0"
                :title="`mentee: ${match.mentee.name}`"
                :user="match.mentee"
                :key="match.mentee.id"
                userPhotoRadius="tw-rounded-3--force"
                userPhotoSize="tw-w-8 tw-h-8"
                userNameFontClass="tw-text-xs leading-15"
              />

              <div>
                <p class="tw-text-xs tw-px-2 opacity-54">
                  {{match.mentee.email}}
                  <span class="tw-ml-1"></span>
                </p>
                <p class="tw-text-xs tw-px-2 opacity-54">
                  {{match.mentee.working_num}}
                  <span class="tw-ml-1"></span>
                </p>
              </div>

            </div>

          </div>
        </div>

        <template v-if="match.lastCommunicationDate">
          <div class="tw-inline-flex tw-justify-end tw-items-center">
            <p class="tw-text-xs leading-15 tw-my-4 tw-text-blue-500 tw-mx-12">
              last spoke <br>{{ match.lastCommunicationDate | toJsDate(true) | moment('Do-MM-YYYY') }}
            </p>

          </div>
        </template>

        <!-- <template v-else-if="listType === 'pending-requests'">
          <div class="tw-inline-flex tw-justify-end tw-items-center">
            <p class="tw-text-sm leading-17 tw-my-4 opacity-78 tw-mx-12">
              sent 4 months ago
            </p>

            <BaseButton
              text="Action"
              class="tw-mr-8 tw-my-4 tw-bg-gray-200"
            />
          </div>
        </template>

        <template v-else>
          <div class="tw-inline-flex tw-justify-end tw-items-center">
            <p class="tw-text-sm leading-17 tw-my-4 tw-text-gray-500 tw-mx-12">
              started 12th March 2020
            </p>

            <BaseButton
              text="Action"
              class="tw-mr-8 tw-my-4 tw-bg-gray-200"
            />
          </div>
        </template> -->

        <template>
          <div class="tw-inline-flex tw-justify-end tw-items-center">
            <BaseButton
              text="End"
              class="tw-mr-8 tw-my-4 tw-bg-red-100 tw-border-red-500 tw-text-red-500 transition-fast tw-opacity-25 group-hover:tw-opacity-100"
              confirm
              confirm-message="You are about to remove a match permanently.<br> Are you sure?"
              @click="endMatch(match)"
            />
          </div>
        </template>

      </div>

      <!-- <div class="divider tw-w-full tw-border-t tw-border-app-black-11 opacity-31" :key="index"></div> -->
    </template>

  </ViewMore>
</template>

<script>
import UserVertical from '@/components/UserVertical.vue';

export default {
  name: 'MatchesList',
  components: {
    UserVertical,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
    delayedRequests: {
      type: Boolean,
      default: false,
    },
    requests: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    listType() {
      if (this.delayedRequests) return 'delayed-requests';
      if (this.requests) return 'pending-requests';

      return 'matches';
    },
  },
  methods: {
    async endMatch(match) {
      const formData = {
        mentorId: match.mentor.id,
        menteeId: match.mentee.id,
        groupId: match.groupid,
      };

      const result = await this.$store.dispatch('endMatch', formData);

      if (!result) {
        this.$toasted.global.appError({ errorMessage: 'We could not end the match. Please try again.' });
      } else {
        this.$emit('update', true);
      }
    },
  },
};
</script>

<style>

</style>
